import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StandardModal from './StandardModal';
import InfoIcon from '../../icon/InfoIcon';
import { ModalContext } from '../../../../contexts/ModalContext';

interface ConfirmationModalProps {
  title?: string;
  description?: string | JSX.Element;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  alt?: boolean;
  confirmDisabled?: boolean;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  description,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  alt,
  confirmDisabled,
}): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <ModalContext.Consumer>
      {(value) => (
        <ModalContext.Provider value={{ ...value, modalWidth: 'w-auto', closeOnBackdropClick: true, modalMaxWidth: 'max-w-xl' }}>
          <StandardModal
            title={
              <div className="flex items-center gap-1">
                <InfoIcon className="h-6 w-6" />
                <div className="truncate pr-4">{title || ''}</div>
              </div>
            }
            cancelButtonTitle={cancelText || t('modals.confirmation.cancel')}
            confirmButtonTitle={confirmText || t('modals.confirmation.confirm')}
            onCancelClick={onCancel}
            onConfirmClick={onConfirm}
            alt={alt}
            confirmDisabled={confirmDisabled}
          >
            <div className="my-2">{description}</div>
          </StandardModal>
        </ModalContext.Provider>
      )}
    </ModalContext.Consumer>
  );
};

export default ConfirmationModal;
